/*=============== CARD ===============*/
.container2{
   display: grid;
   place-items: center;
   margin-inline: 1.5rem;
   padding-block: 5rem;
}

.card{
   &__container2{
      display: grid;
      row-gap: 3.5rem;
   }
   &__article{
      position: relative;
      overflow: hidden;
   }
   &__img{
      width: 500px;
      border-radius: 1.5rem;
   }
   &__data{
      width: 280px;
      background-color: var(--container-color);
      padding: 1.5rem 2rem;
      box-shadow: 0 8px 24px hsla(0, 0%, 0%, .15);
      border-radius: 1rem;
      position: absolute;
      bottom: -9rem;
      left: 0;
      right: 0;
      margin-inline: auto;
      opacity: 0;
      transition: opacity 1s 1s;
   }
   &__description{
      display: block;
      font-size: var(--small-font-size);
      margin-bottom: .25rem;
   }
   &__title{
      font-size: var(--h2-font-size);
      font-weight: 500;
      color: var(--title-color);
      margin-bottom: .75rem;
   }
   &__button{
      text-decoration: none;
      font-size: var(--small-font-size);
      font-weight: 500;
      color: var(--first-color);

      &:hover{
         text-decoration: underline;
      }
   }
   // Naming animations in hover
   &__article:hover &__data{
      animation: show-data 1s forwards;
      opacity: 1;
      transition: opacity .3s;
   }
   &__article:hover{
      animation: remove-overflow 2s forwards;
   }
   &__article:not(:hover){
      animation: show-overflow 2s forwards;
   }
   &__article:not(:hover) &__data{
      animation: remove-data 1s forwards;
   }
}

/* Card animation */
@keyframes show-data{
   50%{
      transform: translateY(-10rem);
   }
   100%{
      transform: translateY(-7rem);
   }
}

@keyframes remove-overflow{
   to{
      overflow: initial;
   }
}

@keyframes remove-data{
   0%{
      transform: translateY(-7rem);
   }
   50%{
      transform: translateY(-10rem);
   }
   100%{
      transform: translateY(.5rem);
   }
}

@keyframes show-overflow{
   0%{
      overflow: initial;
      pointer-events: none;
   }
   50%{
      overflow: hidden;
   }
}


@media (max-width: 767px) {
   .container2 {
     display: flex;
     flex-direction: column; /* Postavite smjer fleksibilnog kontejnera na column za manje ekrane */
     align-items: center; /* Centrirajte elemente po horizontali */
     padding: 2rem; /* Prilagodite vrednost paddinga */
   }
 }
 

 @media (max-width: 767px) {
   .card__article {
     width: 100%; /* Postavite širinu kartica na 100% za manje ekrane */
   }
 }
 